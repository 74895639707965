import React, { Component } from 'react';
import FacetTemplateJsx from './FacetTemplate';

class Facet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      showProducts: false,
      displayStyle: {
        display: 'block',
        animation: 'display 250ms ease-in-out'
      },
      hideStyle: {
        display: 'none',
        animation: 'display 270ms ease-in-out',
        animationFillMode: 'forwards'
      },
      sources: [],
      categories: [],
      products: []
    };
  }

  static getDerivedStateFromProps(props, state) {
    let facets = props.facets || [];
    let sources =
      (
        facets.find(item => {
          return item.name === 'Source';
        }) || {}
      ).values || [];
    let categories =
      (
        facets.find(item => {
          return item.name === 'Category';
        }) || {}
      ).values || [];
    let products = props.products || [];
    return {
      sources: sources,
      categories: categories,
      products: products,
      selectedFacets: props.selectedFacets,
      selectedProducts: props.selectedProducts
    };
  }

  toggleProducts = () => {
    this.setState({
      showProducts: !this.state.showProducts
    });
  };

  render() {
    return (
      <FacetTemplateJsx toggleProducts={this.toggleProducts} {...this.state} />
    );
  }
}

export default Facet;
