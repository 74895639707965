import * as types from './actionTypes';
import Api from './api';

export const getMeganav = inType => {
  return dispatch => {
    return Api.getMeganav(inType).then(content => {
      let payload = {};
      payload[inType] = content;
      dispatch({
        type: types.GET_COMPONENT,
        payload: payload
      });
      return content;
    });
  };
};
