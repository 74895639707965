import React, { Component } from 'react';
import { connect } from 'react-redux';
import SearchJsx from './SearchTemplate';
import apiCommon from '../../../redux/apiCommon';

function checkSelected(stateSelected, propsResponse, removeFacet) {
  propsResponse.forEach(facet => {
    let values = facet.values || [];
    if (values.length < 1) {
      return;
    }
    values.forEach(value => {
      if (!value.selected) {
        return;
      }

      let key = buildFacetKey(facet.name, value.name);
      if (key === removeFacet) {
        return;
      }

      if (stateSelected.indexOf(key) < 0) {
        stateSelected.push(key);
      }
    });
  });
}

function buildFacetKey(category, value) {
  return category + ':"' + value + '"';
}

function queryToState() {
  let location = new URLSearchParams(window.location.search);
  let pageNumber = parseInt(location.get('page-number'));
  let referrer = document.referrer;

  let products = location.getAll('products').map(product => {
    let tokens = product.split(':');
    let name = tokens[0];
    let value = tokens[1];
    if (value.startsWith('"')) {
      return product;
    } else {
      return name + ':"' + value + '"';
    }
  });

  return {
    q: location.get('q') || '',
    pageNumber: isNaN(pageNumber) ? 1 : pageNumber,
    selectedFacets: location.getAll('facets'),
    selectedProducts: [...new Set(products)],
    referrer: referrer
  };
}

function stateToQuery(state) {
  let input = {
    q: state.q,
    'page-number': state.pageNumber,
    facets: state.selectedFacets,
    products: state.selectedProducts
  };
  let newQueryString = apiCommon.objectToQueryParams(input);
  window.history.replaceState(
    null,
    null,
    window.location.pathname + '?' + newQueryString
  );
}

class SearchView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      facetRef: React.createRef(),
      suggestRef: React.createRef(),
      qRef: React.createRef(),
      selectedFacets: [],
      selectedProducts: [],
      pageNumber: 1,
      ...queryToState()
    };
  }

  componentDidMount() {
    /*
     * this is a little crude. unfortunately, this tool/library (react-bootstrap-typeahead)
     * [see SearchBar.jsx] doesn't really provide a better alternative.
     */
    let input = this.state.qRef.current;
    let orig = input._handleInputChange;
    input._handleInputChange = event => {
      this.setQ(event);
      orig(event);
    };
    /*
     * yet another crude limitation of this library...
     * See: https://github.com/ericgio/react-bootstrap-typeahead/issues/266
     */
    input.state.text = this.state.q;

    this.search().then(result => {
      //referrer should only be used once.
      this.setState({
        referrer: null
      });

      /* crude check if the css is loaded */
      let cssCheck = window.getComputedStyle(
        document.getElementsByClassName('mlbs4-facets__title')[0]
      ).textTransform;
      if (cssCheck === 'none') {
        /* first load */
        document.querySelector(
          'link[href*="https://docs.marklogic.com"]'
        ).onload = function() {
          document.getElementById('root').style.display = 'block';
        };
      } else {
        /* page refresh */
        document.getElementById('root').style.display = 'block';
      }
    });
  }

  static getDerivedStateFromProps(props, state) {
    //this is getting invoked prior to search when deselecting
    let currentFacets = [...state.selectedFacets];
    checkSelected(currentFacets, props.facets, state.removeFacet);

    let currentProducts = [...state.selectedProducts];
    checkSelected(currentProducts, props.products, state.removeProduct);

    let result = {
      selectedFacets: currentFacets,
      selectedProducts: currentProducts
    };

    return result;
  }

  toggleFacetDisplay = () => {
    let facet = this.state.facetRef.current;
    let classList = facet.classList;
    classList.toggle('show');
    document.body.classList.toggle('modal-open');
  };

  hideFacets = () => {
    let facet = this.state.facetRef.current;
    facet.classList.remove('show');
    document.body.classList.remove('modal-open');
  };

  typeAheadSubmit = event => {
    if (event.keyCode !== 13) {
      return;
    }
    this.submitSearch(event);
  };

  submitSearch = event => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.setState(
      {
        pageNumber: 1
      },
      this.search
    );
  };

  search = () => {
    return this.props
      .search(
        this.state.q,
        this.state.selectedFacets,
        this.state.selectedProducts,
        this.state.pageNumber,
        this.state.pageLength,
        this.state.referer
      )
      .then(result => {
        stateToQuery(this.state);
      });
  };

  toggleFacet = (category, value) => {
    let key = this.buildFacetKey(category, value);
    let facets = [...this.state.selectedFacets];
    let removeFacet = null;
    if (facets.indexOf(key) < 0) {
      facets.push(key);
      removeFacet = null;
    } else {
      removeFacet = facets.splice(facets.indexOf(key), 1)[0];
    }
    this.setState(
      {
        selectedFacets: facets,
        removeFacet: removeFacet,
        pageNumber: 1
      },
      this.search
    );
  };

  toggleProduct = (category, value) => {
    let key = this.buildFacetKey(category, value);
    let products = [...this.state.selectedProducts];
    let removeProduct = null;
    if (products.indexOf(key) < 0) {
      let existing = products.find(item => item.startsWith(category + ':'));
      if (products.indexOf(existing) >= 0) {
        removeProduct = products.splice(products.indexOf(existing), 1)[0];
      }
      products.push(key);
    } else {
      removeProduct = products.splice(products.indexOf(key), 1)[0];
    }
    this.setState(
      {
        selectedProducts: products,
        removeProduct: removeProduct,
        pageNumber: 1
      },
      this.search
    );
  };

  goToPage = pageNumber => {
    this.setState(
      {
        pageNumber: pageNumber
      },
      this.search
    );
  };

  addPage = pageNumber => {
    let value = this.state.pageNumber + pageNumber;
    if (value < 1) {
      value = 1;
    }
    let max = Math.ceil(this.props.total / 10);
    if (value > max) {
      value = max;
    }

    if (value === this.state.pageNumber) {
      //nothing to do
      return;
    }

    this.setState(
      {
        pageNumber: value
      },
      this.search
    );
  };

  setQ = event => {
    this.setState({
      q: event.target.value
    });
  };

  applySuggest = q => {
    this.setState(
      {
        q: q
      },
      this.search
    );
  };

  suggest = () => {
    this.props.suggest(this.state.q).then(() => {
      //this is just far too crude
      let current = this.state.suggestRef.current;
      if (current.ariaExpanded === 'false') {
        current.click();
      }
    });
  };

  buildFacetKey = buildFacetKey;

  render() {
    return (
      <SearchJsx
        {...this.state}
        toggleFacetDisplay={this.toggleFacetDisplay}
        toggleFacet={this.toggleFacet}
        hideFacets={this.hideFacets}
        toggleProduct={this.toggleProduct}
        goToPage={this.goToPage}
        addPage={this.addPage}
        setQ={this.setQ}
        applySuggest={this.applySuggest}
        submitSearch={this.submitSearch}
        buildFacetKey={this.buildFacetKey}
        typeAheadSubmit={this.typeAheadSubmit}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let source = state.search || {};
  return {
    total: source.total || 0,
    result: source.searchResult || [],
    facets: source.facets || [],
    products: source.products || [],
    suggestions: source.suggestions || [],
    searchRunning: source.searchRunning,
    suggestRunning: source.suggestRunning
  };
};

export default connect(mapStateToProps)(SearchView);
