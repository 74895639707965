import apiCommon from '../apiCommon';
require('isomorphic-fetch');

export default {
  getMeganav: inTarget => {
    return fetch('/meganav/' + inTarget).then(
      apiCommon.textHandler,
      apiCommon.errorHandler
    );
  }
};
