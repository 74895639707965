import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { SearchContainer } from '../containers';

const Routes = ({ isAuthenticated }, ...rest) => {
  return (
    <Switch>
      <Route path="/" render={() => <SearchContainer />} />
    </Switch>
  );
};

export default Routes;
