import * as types from '../actionTypes';
export default (state = {}, action) => {
  let payload = action.payload || {};
  switch (action.type) {
    case types.GET_COMPONENT:
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
};

const selectors = {};

export { selectors };
