import React, { Component } from 'react';
import ResultsTemplateJsx from './ResultsTemplate';

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      searchRunning: props.searchRunning,
      result: props.result,
      toggleRef: React.createRef()
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      searchRunning: props.searchRunning,
      result: props.result
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.searchRunning !== prevState.searchRunning) {
      //switch and toggle
      this.toggleResults();
    }
  }

  toggleResults = () => {
    let toggle = this.state.toggleRef;
    if (toggle.current) {
      toggle.current.click();
    }
  };

  render() {
    return (
      <ResultsTemplateJsx toggleResults={this.toggleResults} {...this.state} />
    );
  }
}

export default Results;
