import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class AppContainer extends React.Component {
  constructor(props) {
    super(props);
    // Move fetch() to global so errors, presently just 401s, can be intercepted
    const fetch = global.fetch;
    global.fetch = function(url, options) {
      return fetch(url, options).then(response => {
        if (response.status === 401) {
          props.getAuthenticationStatus();
        }
        return response;
      });
    };
  }

  render() {
    return this.props.render(this.props);
  }
}

AppContainer.propTypes = {
  render: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps
  };
};

export default connect(mapStateToProps)(AppContainer);
