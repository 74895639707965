import React from 'react';
import SearchBarJsx from './SearchBar'
import Facet from './Facet'
import Results from './Results'
import PaginationJsx from './Pagination'
import './search.css'
import { Helmet } from "react-helmet"

const SearchJsx = (props) => {
  return <>
    {/* header */}
    <SearchBarJsx {...props} />
    <div id="mlbs4-search-results">
      <div className="mlbs4-container">
        <div className="mlbs4-row d-lg-none d-xl-none">
          <div className="col col--mlbs4-filter">
            <button id="mlbs4-filter" className="mlbs4-facets-button" data-toggle="modal" data-target=".mlbs4-facets" onClick={props.toggleFacetDisplay}>
              <span className="filter-icon">
                <i className="fa fa-sliders" aria-hidden="true">&nbsp;</i>
              </span>
              <span className="filter-text">Filter Results</span>
            </button>
          </div>
        </div>
        <div className="mlbs4-row">
          <Facet {...props} />
          <Results {...props} />
        </div>
        <PaginationJsx {...props} />
      </div>
    </div>
    
    <Helmet>
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Global Search | MarkLogic</title>
      <meta name="description" content="MarkLogic's Global Search allows you to quickly find content within our main websites. The search is powered by the MarkLogic Data Hub." />
      <meta name="theme-color" content="#ffffff" />
      {/*<!-- bootstrap stuff -->*/}
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/jqueryui/1.12.1/jquery-ui.css" crossOrigin="anonymous" />
      <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.2.1/css/bootstrap.min.css" crossOrigin="anonymous" />
      <link rel="canonical" href="https://globalsearch.marklogic.com/" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Global Search | MarkLogic" />
      <meta property="og:description" content="MarkLogic's Global Search allows you to quickly find content within our main websites. The search is powered by the MarkLogic Data Hub." />
      <meta property="og:url" content="https://globalsearch.marklogic.com/" />
      <meta property="og:site_name" content="MarkLogic Global Search" />
      <meta name="robots" content="noindex,follow"/>
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content="MarkLogic's Global Search allows you to quickly find content within our main websites. The search is powered by the MarkLogic Data Hub." />
      <meta name="twitter:title" content="Global Search | MarkLogic" />
      <meta name="twitter:site" content="@marklogic" />
      <meta name="twitter:creator" content="@marklogic" />
    </Helmet>
  </>
};

export default SearchJsx;
