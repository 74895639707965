import React from 'react';
import { Container } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import AppContainer from './containers/AppContainer';

import Routes from './components/Routes';
import 'font-awesome/css/font-awesome.min.css';

import TagManager from 'react-gtm-module';
const tagManagerArgs = {
  gtmId: 'GTM-TX8G7Q'
};
TagManager.initialize(tagManagerArgs);

const App = appProps => (
  <AppContainer
    {...appProps}
    render={props => (
      <div>
        <Container fluid={true} style={{ padding: 0 }}>
          <Routes {...props} />
        </Container>
      </div>
    )}
  />
);

export default withRouter(App);
