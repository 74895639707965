import React from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import "./SearchBar.css"
/*
 * see: http://ericgio.github.io/react-bootstrap-typeahead/
 */
const SearchBarJsx = (props) => {
  return <div className="header-image header-image--search">
      <h1>Search MarkLogic</h1>
      <div className="mlbs4-container">
        <div className="mlbs4-row justify-content-center">
          <div className="col-md-10">
            <form method="GET" className="search-form ml-search" role="search" autoComplete="off" onSubmit={props.submitSearch}>
              <div className="search-container">
                <AsyncTypeahead
                  ref={props.qRef}
                  onKeyDown={props.typeAheadSubmit}
                  id="q"
                  value={props.q}
                  filterBy={() => true}
                  isLoading={props.suggestRunning || false}
                  labelKey="Search"
                  minLength={3}
                  //silly library, this applies when an item is selected
                  onChange={props.applySuggest}
                  onSearch={props.suggest}
                  options={props.suggestions}
                  placeholder="Search"
                  renderMenuItemChildren={(option, props) => (
                    <span>{option}</span>
                  )}
                />              
              </div>
              <button type="submit">
                <i className="fa fa-search">&nbsp;</i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
};

export default SearchBarJsx;
