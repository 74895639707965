import React from 'react';
import {Accordion, Card} from 'react-bootstrap';

const ResultsTemplateJsx = (props) => {
  let target = props.searchRunning ? "0"
      : props.result.length < 1 ? "1"
      : "2";
  return <Accordion defaultActiveKey="0" className="col col-lg-8 col-xl-9 mlbs4-results">
    <Card style={{border:0}}>
      <Accordion.Toggle eventKey={target} style={{display:'none'}} ref={props.toggleRef}>
        <span />
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">
        <div className="fa-3x text-center">
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      </Accordion.Collapse>
    </Card>
    <Card style={{border:0}}>
      <Accordion.Collapse eventKey="1">
        <div className="mlbs4-row"><div className="col mlbs4-no-results"><p>No Results Found.</p></div></div>
      </Accordion.Collapse>
    </Card>
    <Card style={{border:0}}>
      <Accordion.Collapse eventKey="2">
        <div>
          {
            props.result.map(item => {
              let webDoc = item.extracted[0];
              return <div key={item.uri} className="mlbs4-result">
                <div className="mlbs4-result__subtitle">
                  {
                    item.collections.map(category => {
                      //yes, that extra space is crucial.
                      return <span key={category} className="subtitle-item"> {category}</span>
                    })
                  }
                </div>
                <div className="mlbs4-result__title">
                  <h3>
                    <a href={webDoc.url}>{webDoc.title}</a>
                  </h3>
                </div>
                <div className="mlbs4-result__url">
                  <a href={webDoc.url}>{webDoc.url}</a>
                </div>
                <div className="mlbs4-result__snippet">
                  {
                    item.matches.map(matches => {
                      let matchText = matches['match-text']
                      return matchText.map(text => {
                        return text.highlight 
                        ? <b>{text.highlight}</b>
                        : text
                      })
                    })
                  }
                </div>
              </div>
            })
          }
        </div>
      </Accordion.Collapse>
    </Card>
  </Accordion>
};

export default ResultsTemplateJsx;
