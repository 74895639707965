/* globals require */
import apiCommon from '../apiCommon';
require('isomorphic-fetch');

export default {
  search: (q, facets, products, pageNumber, pageLength, referer) => {
    let params = {
      'rs:q': q,
      'rs:facets': facets,
      'rs:products': products,
      'rs:start': apiCommon.pageNumberToStart(10, pageNumber),
      'rs:referer': referer
    };
    return fetch(apiCommon.buildUrl('/public/search', params), {
      method: 'GET'
    }).then(apiCommon.jsonHandler, apiCommon.errorHandler);
  },
  suggest: q => {
    let params = {
      'rs:q': q
    };
    return fetch(apiCommon.buildUrl('/public/suggest', params), {
      method: 'GET'
    }).then(apiCommon.jsonHandler, apiCommon.errorHandler);
  }
};
