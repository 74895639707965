import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from '../redux/meganav';

class Meganav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      target: props.target
    };
  }

  componentDidMount() {
    this.props.getMeganav(this.state.target);
  }

  /*
   * Yes, we could have used react-helmet instead of this adhoc code... but it's working already.
   */
  addScript = url => {
    const script = document.createElement('script');
    script.src = url;
    script.async = false;
    script.defer = true;
    script.crossOrigin = 'anonymous';
    document.head.appendChild(script);
  };

  addCss = url => {
    const script = document.createElement('link');
    script.href = url;
    script.rel = 'stylesheet';
    script.async = true;
    document.head.appendChild(script);
  };

  render() {
    if (
      this.props.content == null ||
      this.props.scripts == null ||
      this.props.css == null
    ) {
      /*
       * The intent here is to only render header and footer if the scripts and their styles
       * are already available. Primarily due to the fact that header requires to run some scripts
       * where the js libraries are already required.
       */
      return null;
    }

    let target = this.state.target;
    let component = { __html: this.props.content };
    let result = <div dangerouslySetInnerHTML={component}></div>;
    if (target === 'scripts') {
      result = null;
      let pattern = /src=['"]([^"']*)['"]/g;
      if (component != null) {
        let captures = this.props.content.match(pattern);
        if (captures) {
          captures.forEach(capture => {
            let src = capture.substring(
              capture.indexOf('"') + 1,
              capture.length - 1
            );
            this.addScript(src);
          });
        }

        /*
         * required  for the facet popup to work on smaller screens. unable to place this on the header
         * This used to be located at SearchTemplate, however, these depend on jQuery being downloaded first
         * This approach ensures that while avoiding duplicates.
         */
        [
          'https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js',
          'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js',
          'https://cdnjs.cloudflare.com/ajax/libs/jquery/1.12.1/jquery.js',
          'https://cdnjs.cloudflare.com/ajax/libs/jqueryui/1.12.1/jquery-ui.min.js'
        ].forEach(capture => {
          let src = capture;
          this.addScript(src);
        });
      }
    } else if (target === 'stylesheets') {
      this.addCss('https://docs.marklogic.com/css/header.css?2021.02.11.0');
      result = null;
      let pattern = /href=['"]([^"']*)['"]/g;
      if (component != null) {
        let captures = this.props.content.match(pattern);
        if (captures) {
          captures.forEach(capture => {
            let src = capture.substring(
              capture.indexOf('"') + 1,
              capture.length - 1
            );
            this.addCss(src);
          });
        }
      }
    }
    return result;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    content: state.meganav[ownProps.target],
    css: state.meganav['stylesheets'],
    scripts: state.meganav['scripts']
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getMeganav: actions.getMeganav
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Meganav);
