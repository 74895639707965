import React from 'react';


const PaginationJsx = (props) => {
  
  let maxButtons = props.maxButtons || 5;
  let activePage = props.pageNumber || 1;
  let items = Math.ceil(props.total / 10);
  
  function generateButton(i, action, display, specialClass) {
    let dummy = i === activePage || i === '...';
    let style = "paginate__button " + specialClass;
    return <li key={`${i}:${display}`}>
        <button className={style} onClick={() => dummy ? null : action(i)}  dangerouslySetInnerHTML={{__html:display || i}}></button>
      </li>
  }
  
  let half = Math.floor(maxButtons / 2);
  var min = activePage - half;
  if (min < 1) {
    min = 1;
  }
  var max = activePage + half;
  if (max > items) {
    max = items;
  }

  if (activePage - min < half) {
    max = max + (half - (activePage - min));
  }

  if (max - activePage < half) {
    min = min - (half - (max - activePage)) + 1;
  }

  if (min < 1) {
    min = 1;
  }
  if (max > items) {
    max = items;
  }

  let pageNumbers = [];
  pageNumbers.push(generateButton(1, props.goToPage, '&#171;', 'first'));
  pageNumbers.push(generateButton(-1, props.addPage, '&#8249;', 'previous'));
  pageNumbers.push(generateButton(1, props.goToPage, 1, 1 === activePage ? 'active' : null));
  if (min > 2) {
    pageNumbers.push(generateButton('...'));
  }
  for (var i = min; i < max; ++i) {
    if (i === 1 || i === items) {
      continue;
    }
    pageNumbers.push(generateButton(i, props.goToPage, i, i === activePage ? 'active' : null));
  }
  if (items - max > 0) {
    pageNumbers.push(generateButton('...'));
  }
  if (items > 1) {
    pageNumbers.push(generateButton(items, props.goToPage, items, items === activePage ? 'active' : null));
  }
  pageNumbers.push(generateButton(1, props.addPage, '&#8250;', 'next'));
  pageNumbers.push(generateButton(items, props.goToPage, '&#187;', 'end'));
  
  return <div className="mlbs4-row">
    <div className="col">
      <ul id="mlbs4-pagination" className="paginate">
        {
          pageNumbers
        }
      </ul>
    </div>
  </div>
};

export default PaginationJsx;
