import * as types from './actionTypes';
import defaultAPI from './api';

export const search = (
  q,
  facets,
  products,
  pageNumber,
  pageLength,
  referer
) => {
  const API = defaultAPI;
  return dispatch => {
    dispatch({
      type: types.SEARCH_START
    });

    return API.search(
      q,
      facets,
      products,
      pageNumber,
      pageLength,
      referer
    ).then(response => {
      dispatch({
        type: types.SEARCH_END,
        payload: response
      });

      return response;
    });
  };
};

export const suggest = q => {
  const API = defaultAPI;
  return dispatch => {
    dispatch({
      type: types.SUGGEST_START
    });

    return API.suggest(q).then(response => {
      dispatch({
        type: types.SUGGEST_END,
        payload: response
      });

      return response;
    });
  };
};
