import React from 'react';
import {Accordion, Card} from 'react-bootstrap';

function getDefaultVersion(item, selectedProducts){
  let selectedProduct = selectedProducts.find(product => product.startsWith(item.name + ':'))
  let version = selectedProduct != null ? selectedProduct.split(':')[1].replaceAll('"', '') : null;
  
  let defaultValue = item.values.find(value => {
    return value.selected || value.name === version
  })
  
  if (defaultValue == null) {
    defaultValue = item.values.find(value => {
      return value.default
    })
  }
  return defaultValue;
}

const FacetTemplate = (props) => {
  return <div className="d-lg-block col col-lg-4 col-xl-3 mlbs4-facets modal fade" ref={props.facetRef}>
    <div className="mlbs4-facets__inner modal-dialog">
      <div className="modal-content">
        <div className="mlbs4-facets__header modal-header">
          <span className="icon">
            <i className="fa fa-sliders" aria-hidden="true">&nbsp;</i>
          </span>
          <span className="text">Filter Results</span>
          <span id="mlbs4-overlay-close" className="close" data-dismiss="modal" onClick={props.hideFacets}>&nbsp;</span>
        </div>
        <div className="modal-body">
          <div className="mlbs4-facets__group">
            <div className="mlbs4-facets__title">Source</div>
            {
              props.sources.map(item => {
                //selected would have to take into consideration both local state and props.
                let key = props.buildFacetKey('Source', item.name);
                let selected = item.selected || props.selectedFacets.indexOf(key) >= 0;
                let style = "mlbs4-facets__checkbox" + (selected ? " mlbs4-facets__checkbox--checked" : "")
                return <div className={style} key={key} onClick={() => props.toggleFacet('Source', item.name, true)}>{item.display}</div>
              })
            }
          </div>
          <div className="mlbs4-facets__group">
            <div className="mlbs4-facets__title">Category</div>
            {
              props.categories.map(item => {
                let key = props.buildFacetKey('Category', item.name);
                let selected = item.selected || props.selectedFacets.indexOf(key) >= 0;
                let style = "mlbs4-facets__checkbox" + (selected ? " mlbs4-facets__checkbox--checked" : "")
                return <div className={style} key={key} onClick={() => props.toggleFacet('Category', item.name, true)}>{item.display}<span className="mlbs4-facets__count">{item.count}</span></div>
              })
            }
          </div>
          {
            props.products == null || props.products.length < 1 ? null :
            <Accordion defaultActiveKey="1" className="mlbs4-facets__group">
              <Card style={{border:0}}>
                <div className="mlbs4-facets__title" >Products 
                  <Accordion.Toggle eventKey={props.showProducts ? "1" : "0"} className="btn pull-right fa" style={{padding:0,margin:0}}>
                    <span className={props.showProducts ? "fa fa-chevron-up" : "fa fa-chevron-down"} onClick={ props.toggleProducts }/>
                  </Accordion.Toggle>
                </div>
                <Accordion.Collapse eventKey="0">
                  <Card.Body style={{border:"none", padding:0}}>
                  {
                    props.products.map(item => {
                      let defaultValue = getDefaultVersion(item, props.selectedProducts);
                      let key = props.buildFacetKey(item.name, defaultValue.name);
                      let selected = props.selectedProducts.find(product => product.startsWith(item.name + ':')) != null;
                      let style = "mlbs4-facets__checkbox" + (selected ? " mlbs4-facets__checkbox--checked" : "")
                      return <div key={key} className="mlbs4-facets__group">
                        <div className={style} onClick={() => props.toggleProduct(item.name, defaultValue.name, true)}>{item.name}</div>
                        {
                          item.values.map(version => {
                            let key = props.buildFacetKey(item.name, version.name);
                            let selected = version.selected || props.selectedProducts.indexOf(key) >= 0;
                            let style = "mlbs4-facets__radio" + (selected ? " mlbs4-facets__radio--checked" : "")
                            return <div key={key} className={style} onClick={() => props.toggleProduct(item.name, version.name, true)}>{version.display}</div>
                          })
                        }
                      </div>
                    })
                  }
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card style={{border:0}}>
                <Accordion.Collapse eventKey="1">
                  <Card.Body style={{border:"none", padding:0}}>
                  {
                    props.products.map(item => {
                      let defaultValue = getDefaultVersion(item, props.selectedProducts);
                      let key = props.buildFacetKey(item.name, defaultValue.name);
                      let selected = props.selectedProducts.find(product => product.startsWith(item.name + ':')) != null;
                      let style = "mlbs4-facets__checkbox" + (selected ? " mlbs4-facets__checkbox--checked" : "")
                      return <div key={key} className={style} onClick={() => props.toggleProduct(item.name, defaultValue.name, true)}>{item.name}
                        <span className="mlbs4-facets__count">{defaultValue.display}</span>
                      </div>
                    })
                  }
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          }
        </div>
        <div className="mlbs4-facets__footer modal-footer">
          <button className="mlbs4-facets-button" data-toggle="modal" data-target=".mlbs4-facets" onClick={props.hideFacets}>
            <span className="filter-icon">
              <i className="fa fa-sliders" aria-hidden="true">&nbsp;</i>
            </span>
            <span className="filter-text" >Close Filters</span>
          </button>
        </div>
      </div>
    </div>
  </div>
};

export default FacetTemplate;
