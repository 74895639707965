var esc = encodeURIComponent;

function objectToQueryParams(params) {
  if (params == null) {
    return null;
  }

  return Object.keys(params)
    .map(k => {
      if (params[k] == null) {
        return null;
      }
      if (Array.isArray(params[k])) {
        let values = params[k];
        let escaped = values.map(value => `${esc(k)}=${esc(value)}`);
        return escaped.join('&');
      } else {
        return `${esc(k)}=${esc(params[k])}`;
      }
    })
    .filter(item => item != null && item !== '')
    .join('&');
}

function convertJsonResponseToError(response) {
  return response.json().then(
    error => {
      let errorData = [];
      if (error.data && error.data.map) {
        error.data.forEach(datum => {
          if (datum.indexOf('<a') > 0) {
            errorData.push({
              message: datum.substr(0, datum.indexOf('<a'))
            });
          } else {
            errorData.push({
              message: datum
            });
          }
        });
      }
      if (error.errors && error.errors.map) {
        error.errors.forEach(datum => {
          if (datum.indexOf('<a') > 0) {
            errorData.push({
              message: datum.substr(0, datum.indexOf('<a'))
            });
          } else {
            errorData.push({
              message: datum
            });
          }
        });
      }
      //TODO: yes... different API seems to have different error messaging structure.
      let message = error.msg || error.error || response.statusText;
      let customError = new Error(message);
      customError.data = errorData;
      customError.status = response.status;
      throw customError;
    },
    errorNotJson => {
      let error = new Error(response.statusText);
      error.status = response.status;
      throw error;
    }
  );
}

export default {
  objectToQueryParams: objectToQueryParams,
  buildUrl: (path, params, baseUri) => {
    let pair = [path, objectToQueryParams(params)].filter(
      item => item != null && item !== ''
    );
    return new URL(pair.join('?'), baseUri || document.baseURI).toString();
  },
  jsonHandler: response => {
    if (!response.ok) {
      return convertJsonResponseToError(response);
    }
    return response.json();
  },
  textHandler: response => {
    if (!response.ok) {
      return convertJsonResponseToError(response);
    }
    return response.text();
  },
  blobHandler: response => {
    if (!response.ok) {
      return convertJsonResponseToError(response);
    }
    return response.blob();
  },
  errorHandler: error => {
    /* eslint-disable no-console */
    console.log('error:', error);
    /* eslint-enable no-console */
    throw error;
  },
  pageNumberToStart: (pageLength, pageNumber) => {
    return (pageNumber - 1) * pageLength + 1;
  }
};
