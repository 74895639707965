import * as types from '../actionTypes';
export default (state = {}, action) => {
  let payload = action.payload || {};
  switch (action.type) {
    case types.SEARCH_START: {
      return {
        ...state,
        searchRunning: true
      };
    }
    case types.SEARCH_END: {
      let response = payload.response || {};
      return {
        ...state,
        facets: payload.facets || [],
        products: payload.products || [],
        total: response.total || 0,
        searchResult: response.results || [],
        searchRunning: false
      };
    }
    case types.SUGGEST_START: {
      return {
        ...state,
        suggestions: [],
        suggestRunning: true
      };
    }
    case types.SUGGEST_END: {
      return {
        ...state,
        suggestions: action.payload,
        suggestRunning: false
      };
    }
    default:
      return state;
  }
};
